import React from "react";
import Link from "../link";
import { getImageUrl } from "takeshape-routing";

const PostSwitcher = ({ data }) => {
  return (
    <div className="box brand-img gs-animate">
      <div className="container container--bordered container--fluid">
        <div className="brand-img__wrap">
          <div
            className="brand-img__image box box--bottom-bordered"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <img
              src={getImageUrl(data.banner2.path, {fit: "crop", auto: "compress"})}
              alt={data.banner2.title || ''}
              itemProp="contentUrl"
            />
          </div>
        </div>
        <div className="grid-layout post-switcher box box--splitted box--bottom-bordered">
          <div className="grid-layout__child post-switcher__switch-button post-switcher__switch-button--prev">
            <Link to={data.prev.link} tabIndex="0">
              <h2 className="h1 indent-text complex-heading">
                <sup>Previous</sup>
                {data.prev.title}
                <br></br> {data.prev.description}
              </h2>
            </Link>
          </div>
          <div className="grid-layout__child post-switcher__switch-button post-switcher__switch-button--next">
            <Link to={data.next.link} tabIndex="0">
              <h2 className="h1 indent-text complex-heading">
                <sup>Next</sup>
                {data.next.title}
                <br></br> {data.next.description}
              </h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSwitcher;

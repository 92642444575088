import React from "react";
import { getImageUrl } from "takeshape-routing";

const StudyTileModule = ({ caption, number, images = [] }) => {
  return (
    <section className="section gs-animate">
      <div className="container container--bordered container--fluid">
        {images.length > 1 && <div className="grid-layout box box--splitted box--bottom-bordered">
          <div
            className="grid-layout__child box image-box"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <img
              src={getImageUrl(images[1], {fit: "crop", auto: "compress"})}
              alt="Description"
              itemProp="contentUrl"
            />
          </div>
          <div className="grid-layout__child container flex-column flex-column--desktop-column flex-column--content-end">
            <div className="box"></div>
          </div>
        </div>
        }
        <div className="grid-layout box box--splitted box--bottom-bordered">
          {(!images.length && number % 2 === 0) && <div className="grid-layout__child box"></div>}
          <div className="grid-layout__child box">
            {caption && <div className="box flex-item post">
              <div className="index-number">0{number}</div>
              <div>
                <h3>{caption.title}</h3>
                <p>{caption.text}</p>
              </div>
            </div>
            }
          </div>
          {images.length > 0 && (
            <div
              className="grid-layout__child box image-box"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <img
                src={getImageUrl(images[0], {fit: "crop", auto: "compress"})}
                alt="Description"
                itemProp="contentUrl"
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default StudyTileModule;

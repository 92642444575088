import React from "react";

import LazyImage from "./../lazyImage";
import { getImageUrl } from "takeshape-routing";

const BackgroundSection = props => {
  return (
    <LazyImage
      src={getImageUrl(props.banner.path, {fit: "crop", auto: "compress"})}
      className={props.className}
      backgroundColor={`#040e18`}
    >
      {props.children}
    </LazyImage>
  );
};

export default BackgroundSection;

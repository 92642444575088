import React from "react";
import { getImageUrl } from "takeshape-routing";

const BrandImage = ({ data }) => {

  return (
    <div className="box brand-img gs-animate">
      <div className="container container--bordered container--fluid">
        <div className="brand-img__wrap">
          <div
            className="brand-img__image box box--bottom-bordered long-box"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <img
              src={getImageUrl(data.banner1.path, {fit: "crop", auto: "compress"})}
              alt={data.banner1.title || ''}
              itemProp="contentUrl"
            />
          </div>
          <div
            className="brand-img__brand"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <img
              src={getImageUrl(data.brand.path, {fit: "crop", auto: "compress"})}
              alt={data.brand.title || ''}
              itemProp="contentUrl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandImage;

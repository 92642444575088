import React from "react";
import Title from "../heroTitle";

const HeroSectionContent = ({ title, headerTextColor = '#FFFFFF' }) => {
  return (
    <>
      <div className="container container--blend">
        <div className="hero-section__content top-offset">
          <Title className="indent-text mobile-hide" style={{ color: headerTextColor }}>{title}</Title>
        </div>
      </div>
      <div className="container container--bordered container--overlay"></div>
    </>
  );
};

export default HeroSectionContent;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImageUrl } from "takeshape-routing";
import { useStaticQuery, graphql } from "gatsby";

const StudyWorkModule = ({ data }) => {
  const framesData = useStaticQuery(graphql`
    query {
      LaptopFrame: file(relativePath: { eq: "frame01.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 99, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <section className="section gs-animate">
      <div className="container container--bordered container--fluid">
        <div className="grid-layout box box--splitted">
          <div className="grid-layout__child box flex-item post">
            <div className="index-number">01</div>
            <div>
              <h3>{data.captions[0].title}</h3>
              <p>{data.captions[0].text}</p>
            </div>
          </div>
          <div
            className="grid-layout__child box box--mobile-border-top box--bottom-bordered image-box"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <div className="frame">
              <GatsbyImage
                image={framesData.LaptopFrame.childImageSharp.gatsbyImageData}
                alt="Image description"
                itemProp="contentUrl"
              />
              <img
                src={getImageUrl(data.laptopImage.path, {
                  fit: "crop",
                  auto: "compress",
                })}
                className="inner-image"
                alt={data.laptopImage.title || ""}
                itemProp="contentUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudyWorkModule;

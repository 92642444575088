import React from "react";

const Columns = ({ data }) => {
  return (
    <section className="section section-columns box box--top-bordered gs-animate">
      <div className="container container--bordered box box--bottom-bordered">
        <div className="row">
          <div className="row__column content-column">
            <h2 className="h1">{data.headlines.title}</h2>
            <p>{data.headlines.description}</p>
          </div>
          <div className="row__column disciplines-column">
            <ul className="links-list listreset">
              <li className="links-list__item" itemProp="itemListElement">
                <h3>Disciplines</h3>
                <ul
                  className="second-level listreset"
                  itemScope
                  itemType="http://schema.org/ItemList"
                >
                  {data && data["disciplines"]
                    ? data["disciplines"].map(element => {
                        return (
                          <li key={element.title} itemProp="itemListElement">
                            {element.title}
                          </li>
                        );
                      })
                    : false}
                </ul>
              </li>
            </ul>
          </div>
          <div className="row__column location-column">
            <ul
              className="links-list listreset"
              itemScope
              itemType="http://schema.org/ItemList"
            >
              <li className="links-list__item" itemProp="itemListElement">
                <h3>Location</h3>
                <ul
                  className="second-level listreset"
                  itemScope
                  itemType="http://schema.org/ItemList"
                >
                  {data && data["locations"]
                    ? data["locations"].map(element => {
                        return (
                          <li key={element.title} itemProp="itemListElement">
                            {element.title}
                          </li>
                        );
                      })
                    : false}
                </ul>
              </li>
              <li className="links-list__item" itemProp="itemListElement">
                {data && data["featured"] && <h3>Featured</h3>}
                <ul
                  className="second-level listreset"
                  itemScope
                  itemType="http://schema.org/ItemList"
                >
                  {data && data["featured"]
                    ? data["featured"].map(element => {
                        return (
                          <li key={element.title} itemProp="itemListElement">
                            <a
                              href={element.link}
                              tabIndex="0"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {element.title}
                            </a>
                          </li>
                        );
                      })
                    : false}
                </ul>
              </li>
              <li className="links-list__item" itemProp="itemListElement">
                <h3>Visit Live Site</h3>
                <ul
                  className="second-level listreset"
                  itemScope
                  itemType="http://schema.org/ItemList"
                >
                  {data && data["sites"]
                    ? data["sites"].map(element => {
                        return (
                          <li key={element.title} itemProp="itemListElement">
                            <a itemProp="url" href={element.link} tabIndex="0">
                              {element.title}
                            </a>
                          </li>
                        );
                      })
                    : false}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Columns;

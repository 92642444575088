import React from "react";
import Columns from "../case-study-page/columns";
import BrandImage from "../case-study-page/brand-image";
import StudyWorkModule from "../case-study-page/study-work-module";
import StudyImageWorkModule from "../case-study-page/study-image-work-module";
import StudyWorkModuleVertical from "../case-study-page/study-work-module-vertical";
import StudyTileModule from "../case-study-page/study-tile-module";
import PostSwitcher from "../case-study-page/post-switcher";

const CaseStudyPageTemplate = ({ data, children }) => {
  return (
    <div className="main--wrap">
      {children}
      <Columns data={data}></Columns>
      <BrandImage data={data}></BrandImage>
      <StudyWorkModule data={data}></StudyWorkModule>
      <StudyImageWorkModule data={data}></StudyImageWorkModule>
      <StudyWorkModuleVertical data={data}></StudyWorkModuleVertical>
      <StudyTileModule
        caption={data.captions[2]}
        number={3}
        images={[data.screen3.path, data.screen2.path]}
      />
      {
        data.captions.slice(3).map((caption, index) =>
          <StudyTileModule
            caption={caption}
            number={index + 4}
          />
        )
      }
      <PostSwitcher data={data}></PostSwitcher>
    </div>
  );
};

export default CaseStudyPageTemplate;

import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BodyTemplate from "../components/case-study-template/index";

import BackgroundImage from "../components/case-study-page/background-section";
import HeroSectionContent from "../components/case-study-page/hero-section-content";

const WorkDetail = ({ data }) => {
  data = data.takeshape.getWorkDetail;

  const currentPage = data.pageTitle;

  let headerClass;
  if (!data.navbarTheme || data.navbarTheme === "dark") {
    headerClass = "header--dark";
  } else if (data.navbarTheme === "light") {
    headerClass = "header--light";
  } else if (data.navbarTheme === "medium") {
    headerClass = "header--medium";
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "alt-header",
        }}
      />
      <Layout
        headerClass={headerClass}
        breadcrumbClass="breadcrumb--active"
        currentPage={currentPage}
      >
        <Seo
          title={currentPage}
          description="We are a group of passionate designers, developers, and technologists committed to building modern, thoughtful digital experiences in an ever-changing world."
        />
        <BodyTemplate data={data}>
          <BackgroundImage
            banner={data.banner}
            className="section hero-section"
          >
            <HeroSectionContent
              title={data.headline}
              headerTextColor={data.headerTextColor?.hex}
            />
          </BackgroundImage>
        </BodyTemplate>
      </Layout>
    </>
  );
};

export default WorkDetail;

export const query = graphql`
  query($id: ID!) {
    takeshape {
      getWorkDetail(_id: $id) {
        banner1 {
          path
          title
        }
        banner2 {
          path
          title
        }
        banner {
          path
          title
        }
        brand {
          path
          title
        }
        laptopImage {
          path
          title
        }
        phone1 {
          path
          title
        }
        phone2 {
          path
          title
        }
        phone3 {
          path
          title
        }
        phone4 {
          path
          title
        }
        phone5 {
          path
          title
        }
        phone6 {
          path
          title
        }
        screen1 {
          path
          title
        }
        screen2 {
          path
          title
        }
        screen3 {
          path
          title
        }
        captions {
          text
          title
        }
        disciplines {
          title
          link
        }
        featured {
          link
          title
        }
        headline
        headlines {
          title
          description
        }
        summary
        disciplines {
          title
          link
        }
        locations {
          link
          title
        }
        next {
          link
          title
          description
        }
        prev {
          link
          title
          description
        }
        sites {
          link
          title
        }
        headerTextColor {
          hex
        }
        position
        navbarTheme
        pageTitle
        link
        _id
      }
    }
  }
`;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { getImageUrl } from "takeshape-routing";

const StudyImageWorkModule = ({ data }) => {
  const framesData = useStaticQuery(graphql`
    query {
      ImageFrame: file(relativePath: { eq: "frame02.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <div className="section gs-animate image-section">
      <div className="container container--bordered container--fluid">
        <div className="box box--splitted">
          <div className="floated-img-area">
            <div className="holder">
              <div
                className="floated-img floated-img--half floated-img--left"
                itemScope
                itemType="http://schema.org/ImageObject"
              >
                <GatsbyImage
                  image={framesData.ImageFrame.childImageSharp.gatsbyImageData}
                  alt="Image description"
                  itemProp="contentUrl"
                />
                <div className="phone-img">
                  <img
                    src={getImageUrl(data.phone1.path, {
                      fit: "crop",
                      auto: "compress",
                    })}
                    alt={data.phone1.title || ""}
                    itemProp="contentUrl"
                  />
                </div>
              </div>
              <div
                className="floated-img"
                itemScope
                itemType="http://schema.org/ImageObject"
              >
                <GatsbyImage
                  image={framesData.ImageFrame.childImageSharp.gatsbyImageData}
                  alt="Image description"
                  itemProp="contentUrl"
                />
                <div className="phone-img">
                  <img
                    src={getImageUrl(data.phone2.path, {
                      fit: "crop",
                      auto: "compress",
                    })}
                    alt={data.phone2.title || ""}
                    itemProp="contentUrl"
                  />
                </div>
              </div>
              <div
                className="floated-img"
                itemScope
                itemType="http://schema.org/ImageObject"
              >
                <GatsbyImage
                  image={framesData.ImageFrame.childImageSharp.gatsbyImageData}
                  alt="Image description"
                  itemProp="contentUrl"
                />
                <div className="phone-img">
                  <img
                    src={getImageUrl(data.phone3.path, {
                      fit: "crop",
                      auto: "compress",
                    })}
                    alt={data.phone3.title || ""}
                    itemProp="contentUrl"
                  />
                </div>
              </div>
              <div
                className="floated-img floated-img--half floated-img--right"
                itemScope
                itemType="http://schema.org/ImageObject"
              >
                <GatsbyImage
                  image={framesData.ImageFrame.childImageSharp.gatsbyImageData}
                  alt="Image description"
                  itemProp="contentUrl"
                />
                <div className="phone-img">
                  <img
                    src={getImageUrl(data.phone4.path, {
                      fit: "crop",
                      auto: "compress",
                    })}
                    alt={data.phone4.title || ""}
                    itemProp="contentUrl"
                  />
                </div>
              </div>
              <div
                className="floated-img"
                itemScope
                itemType="http://schema.org/ImageObject"
              >
                <GatsbyImage
                  image={framesData.ImageFrame.childImageSharp.gatsbyImageData}
                  alt="Image description"
                  itemProp="contentUrl"
                />
                <div className="phone-img">
                  <img
                    src={getImageUrl(data.phone5.path, {
                      fit: "crop",
                      auto: "compress",
                    })}
                    alt={data.phone5.title || ""}
                    itemProp="contentUrl"
                  />
                </div>
              </div>
              <div
                className="floated-img"
                itemScope
                itemType="http://schema.org/ImageObject"
              >
                <GatsbyImage
                  image={framesData.ImageFrame.childImageSharp.gatsbyImageData}
                  alt="Image description"
                  itemProp="contentUrl"
                />
                <div className="phone-img">
                  <img
                    src={getImageUrl(data.phone6.path, {
                      fit: "crop",
                      auto: "compress",
                    })}
                    alt={data.phone6.title || ""}
                    itemProp="contentUrl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyImageWorkModule;

import React from "react";
import { getImageUrl } from "takeshape-routing";

const StudyWorkModuleVertical = ({ data }) => {
  return (
    <section className="section gs-animate">
      <div className="container container--bordered container--fluid">
        <div className="grid-layout box box--splitted box--bottom-bordered">
          <div className="grid-layout__child"></div>
          <div className="grid-layout__child box">
            <div className="box flex-item post">
              <div className="index-number">02</div>
              <div>
                <h3>{data.captions[1].title}</h3>
                <p>{data.captions[1].text}</p>
              </div>
            </div>
            <div
              className="box box--top-bordered image-box"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <img
                src={getImageUrl(data.screen1.path, {fit: "crop", auto: "compress"})}
                alt={data.screen1.title || ''}
                itemProp="contentUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudyWorkModuleVertical;
